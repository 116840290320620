var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "HandleModal" },
    [
      _c(
        "Modal",
        {
          attrs: {
            visible: _vm.visible,
            closable: true,
            title: (_vm.modalType ? "编辑" : "新建") + "日程",
            size: "normal",
            "mask-closable": false,
          },
          on: {
            ok: _vm.handleOk,
            cancel: function ($event) {
              _vm.visible = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "HandleModal-content" },
            [
              _c(
                "a-form-model",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-col": _vm.labelCol,
                    "wrapper-col": _vm.wrapperCol,
                  },
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "日程标题", prop: "title" } },
                    [
                      _c("a-input", {
                        attrs: {
                          placeholder: "请输入日程标题、会议、活动主题",
                        },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "内容", prop: "content" } },
                    [
                      _c("a-textarea", {
                        attrs: {
                          placeholder: "内容描述",
                          "auto-size": { minRows: 3, maxRows: 5 },
                          "max-length": 100,
                        },
                        model: {
                          value: _vm.form.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "content", $$v)
                          },
                          expression: "form.content",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "全天" } },
                    [
                      _c("a-switch", {
                        attrs: { "default-checked": "" },
                        model: {
                          value: _vm.form.allDay,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "allDay", $$v)
                          },
                          expression: "form.allDay",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.form.allDay ? "日期" : "开始时间" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "flex-center",
                          staticStyle: { "margin-top": "4px" },
                        },
                        [
                          _c("a-date-picker", {
                            style:
                              "width:" + (_vm.form.allDay ? "422px" : "241px"),
                            attrs: { "value-format": "YYYY-MM-DD" },
                            model: {
                              value: _vm.form.startDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "startDate", $$v)
                              },
                              expression: "form.startDate",
                            },
                          }),
                          !_vm.form.allDay
                            ? _c("TimePicker", {
                                staticStyle: { width: "180px" },
                                attrs: { options: _vm.timeSetUp },
                                model: {
                                  value: _vm.form.startTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "startTime", $$v)
                                  },
                                  expression: "form.startTime",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                  !_vm.form.allDay
                    ? _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label:
                              _vm.form.duration !== "5" ? "时长" : "结束时间",
                          },
                        },
                        [
                          _vm.form.duration !== "5"
                            ? _c("DictionariesInput", {
                                attrs: { parameter: "schedule_duration" },
                                model: {
                                  value: _vm.form.duration,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "duration", $$v)
                                  },
                                  expression: "form.duration",
                                },
                              })
                            : _c(
                                "div",
                                { staticClass: "flex-center" },
                                [
                                  _c("a-date-picker", {
                                    style:
                                      "width:" +
                                      (_vm.form.allDay ? "422px" : "241px"),
                                    attrs: {
                                      disabled: "",
                                      "value-format": "YYYY-MM-DD",
                                    },
                                    model: {
                                      value: _vm.form.startDate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "startDate", $$v)
                                      },
                                      expression: "form.startDate",
                                    },
                                  }),
                                  !_vm.form.allDay
                                    ? _c("TimePicker", {
                                        staticStyle: { width: "180px" },
                                        attrs: {
                                          "start-disabled": _vm.form.startTime,
                                          options: _vm.timeSetUp,
                                        },
                                        model: {
                                          value: _vm.form.endTime,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "endTime", $$v)
                                          },
                                          expression: "form.endTime",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "提醒" } },
                    [
                      !_vm.form.allDay
                        ? _c("DictionariesInput", {
                            attrs: { parameter: "schedule_remind" },
                            model: {
                              value: _vm.form.remind,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remind", $$v)
                              },
                              expression: "form.remind",
                            },
                          })
                        : _c("DictionariesInput", {
                            attrs: { parameter: "schedule_allday_remind" },
                            model: {
                              value: _vm.form.remind,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remind", $$v)
                              },
                              expression: "form.remind",
                            },
                          }),
                    ],
                    1
                  ),
                  _c("a-form-model-item", { attrs: { label: "重复" } }, [
                    _c(
                      "div",
                      { staticClass: "flex-center" },
                      [
                        _c("DictionariesInput", {
                          style:
                            "width:" +
                            (_vm.form.repeatType === "1" ? "422px" : "241px"),
                          attrs: { parameter: "schedule_repeat_type" },
                          model: {
                            value: _vm.form.repeatType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "repeatType", $$v)
                            },
                            expression: "form.repeatType",
                          },
                        }),
                        _vm.form.repeatType !== "1"
                          ? _c("a-date-picker", {
                              attrs: {
                                "value-format": "YYYY-MM-DD",
                                placeholder: "结束于",
                              },
                              model: {
                                value: _vm.form.endDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "endDate", $$v)
                                },
                                expression: "form.endDate",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }